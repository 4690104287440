import {Component, OnInit} from '@angular/core';
import {RoutingState} from '../../shared/routing-state.service';

@Component({
  selector: 'app-web-mobile-toolbar',
  templateUrl: './web-mobile-toolbar.component.html',
  styleUrls: ['./web-mobile-toolbar.component.css']
})
export class WebMobileToolbarComponent implements OnInit {

  constructor(private routerState: RoutingState) {
  }

  ngOnInit() {
  }


}
