import {JsonObject, JsonProperty} from 'json2typescript';
import {Router} from '@angular/router';
import {FeedService} from '../network/feed.service';
import {isUndefined} from 'util';

@JsonObject
export class CtaModel {

  static key = 'offer';
  static objTypeRaffle = 'Raffle';
  static objTypeContent = 'Content';
  static objTypeDeepLink = 'Deeplink';
  static objTypePostlist = 'Postlist';
  static objTypePostdetail = 'Postdetail';
  static objTypeQuiz = 'Quiz';
  static objTypeShopper = 'Shopper';
  static objTypeSwipe = 'Swipe';
  static objTypeTree = 'Tree';
  static objTypeActivity = 'Activity';
  static objTypeBeyond = 'Beyond';
  static objTypeVoucher = 'Vouchertype';
  objSubType: any;

  // deprecated
  @JsonProperty('obj', JSON, true)
  obj: any = undefined;

  @JsonProperty('type', String)
  objType: string = undefined;

  @JsonProperty('params', String)
  objParams: any = undefined;

  constructor(type: string, id: string) {
    this.objType = type;
    this.objParams = {};
    this.objParams.id = id;
  }

  public go(feed: FeedService, router: Router, extra?: any) {
    switch (this.objType) {
      case CtaModel.objTypeShopper:
        router.navigateByUrl('/survey/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypeQuiz:
        router.navigateByUrl('/quiz/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypePostdetail:
        router.navigateByUrl('/post/detail/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypePostlist:
        router.navigateByUrl('/post/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypeRaffle:
        router.navigateByUrl('/raffle/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypeContent:
        router.navigateByUrl('/event/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypeSwipe:
        router.navigateByUrl('/swipe/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypeTree:
        router.navigateByUrl('/tree/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypeBeyond:
        router.navigateByUrl('/media-contest/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypeActivity:
        router.navigateByUrl('/gamification/activity/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypeVoucher:
        router.navigateByUrl('/voucher/' + this.objParams.id, extra);
        break;
      case CtaModel.objTypeDeepLink:
        console.log('objTypeDeepLink: ');
        feed.getDeeplink(this.objParams.id)
          .then(deeplink => {
            if (!isUndefined(extra) && extra != null) {
              // angulartics2
              // angulartics2.eventTrack.next({
              //   action: 'outbound',
              //   properties: {
              //     category: 'from: ' + extra,
              //     label: 'to: ' + deeplink.deeplink
              //   }
              // });
            }
            if (this.isRelative(deeplink.deeplink)) {
              router.navigateByUrl(deeplink.deeplink);
            } else {
              window.location.href = deeplink.deeplink;
            }
          })
          .catch(error => {
            console.log(error);
            router.navigateByUrl('/error');
          });
        break;
    }
  }

  private isRelative(redirect: string): boolean {
    return redirect.charAt(0) === '/';
  }

}
