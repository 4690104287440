// Modules
import {NgModule} from '@angular/core';
import {RouterModule, Route, Router} from '@angular/router';
import {HomeComponent} from '../router-component/home/home.component';
import {AuthGuard} from './auth-guard';
import {ToolbarComponent} from '../layout/toolbar/toolbar.component';
import {ErrorComponent} from '../router-component/error/error.component';
import {NotFoundComponent} from '../router-component/not-found/not-found.component';
import {LoadingPageComponent} from '../router-component/loading/loading-page.component';
import {FooterComponent} from '../layout/footer/footer.component';
import {GuestToolbarComponent} from '../layout/guest-toolbar/guest-toolbar.component';
import {SamsungToolbarComponent} from '../layout/samsung-toolbar/samsung-toolbar.component';
import {LandingPromoTecWeekComponent} from '../router-component/promo-tec-week/landing-promo-tec-week/landing-promo-tec-week.component';
import {LandingPromoFoldableComponent} from '../router-component/promo-foldable/landing-promo-foldable/landing-promo-foldable.component';
import {LandingPromoChHolidayComponent} from '../router-component/promo-ch-holiday/landing-promo-ch-holiday/landing-promo-ch-holiday.component';

export const ROUTES: Route[] = [
  {
    path: '',
    component: FooterComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: '404',
        component: NotFoundComponent
      },
      {
        path: 'error',
        component: ErrorComponent
      },
      {
        path: 'landing/galaxyottobre2020',
        component: LandingPromoTecWeekComponent
      },
      {
        path: 'landing/foldable2020',
        component: LandingPromoFoldableComponent
      },
      {
        path: 'landing/holidaycampaign2020',
        component: LandingPromoChHolidayComponent
      },
      {
        path: 'prauth/:token',
        loadChildren: './../router-component/pr-auth/pr-auth.module#PrAuthModule'
      },
      {
        path: 'loading',
        component: LoadingPageComponent
      },
      {
        path: 'recipes',
        loadChildren: './../router-component/recipes/recipes.module#RecipesModule'
      },
      {
        path: 'chef-recipes',
        loadChildren: './../router-component/chef-recipes/chef-recipes.module#ChefRecipesModule'
      },
      {
        path: 'test',
        loadChildren: './../router-component/test/test.module#TestModule'
      },
      {
        path: 'tips',
        loadChildren: './../router-component/tips/tips.module#TipsModule'
      },
      {
        path: 's10-choose',
        loadChildren: './../router-component/s10-choose/s10-choose.module#S10ChooseModule'
      },
      {
        path: 'hmc',
        loadChildren: './../router-component/hmc/hmc.module#HmcModule'
      },
      {
        path: 'summer',
        loadChildren: './../router-component/summer/summer.module#SummerModule'
      },
      {
        path: 'hmc-smarttv-2020',
        loadChildren: './../router-component/hmc-smarttv-2020/hmc0320.module#Hmc0320Module'
      },
      {
        path: 'hmc-s21',
        loadChildren: './../router-component/hmc-s21/hmcs21.module#Hmcs21Module'
      },
      {
        path: 'promo-tec-week',
        loadChildren: './../router-component/promo-tec-week/promo-tec-week.module#PromoTecWeekModule'
      },
      {
        path: 'promo-ch-holiday',
        loadChildren: './../router-component/promo-ch-holiday/promo-ch-holiday.module#PromoChHolidayModule'
      },
      {
        path: 'promo-foldable',
        loadChildren: './../router-component/promo-foldable/promo-foldable.module#PromoFoldableModule'
      },
      {
        path: '',
        component: GuestToolbarComponent,
        children: [
          {
            path: 'landing',
            loadChildren: './../router-component/landing/landing.module#LandingModule'
          }
        ]
      },
      {
        path: '',
        component: SamsungToolbarComponent,
        children: [
          {
            path: 'app-survey',
            loadChildren: './../router-component/app-survey/app-survey.module#AppSurveyModule'
          },
          {
            path: 'survey',
            loadChildren: './../router-component/survey/survey.module#SurveyModule'
          }
        ]
      },
      {
        path: '',
        component: ToolbarComponent, // SamsungToolbarComponent,
        children: [
          {
            path: 'spotify',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/spotify/spotify.module#SpotifyModule'
          }
        ]
      },
      {
        path: '',
        component: ToolbarComponent,
        children: [
          {
            path: 'raffle',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/raffle/raffle.module#RaffleModule'
          },
          {
            path: 'event',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/event/event.module#EventModule'
          },
          {
            path: 'quiz',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/quiz/quiz.module#QuizModule'
          },
          {
            path: 'post',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/post/post.module#PostModule'
          },
          {
            path: 'gamification',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/gamification/gamification.module#GamificationModule'
          },
          {
            path: 'tree',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/tree/tree.module#TreeModule'
          },
          {
            path: 'media-contest',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/media-contest/media-contest.module#MediaContestModule'
          },
          {
            path: 'voucher',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/voucher/voucher.module#VoucherModule'
          },
          {
            path: 'multi-voucher',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/multi-voucher/multi-voucher.module#MultiVoucherModule'
          },
          {
            path: 'xmas',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/xmas/xmas.module#XmasModule'
          },
          {
            path: 'cashbacks10n10',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/cashbacks10n10/cashbacks10n10.module#Cashbacks10n10Module'
          },
          {
            path: 'promoha',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/promoha/promoha.module#PromohaModule'
          },
          {
            path: 'promo-cyber',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/promo-cyber/promo-cyber.module#PromoCyberModule'
          },
          {
            path: 'promoepp',
            canActivate: [AuthGuard],
            loadChildren: './../router-component/promo-epp/promo-epp.module#PromoEppModule'
          }
        ]
      },
      {
        path: '**',
        redirectTo: '404'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES),
    // RouterModule.forRoot(ROUTES, {useHash: true /*, preloadingStrategy: PreloadAllModules*/})
    // interessante la possibilità di avere una custom preloadingStrategy
    // https://github.com/SamVerschueren/ng2-hello-world-lazy-routing-custom-preload
    // Angulartics2Module.forRoot({
    //   pageTracking: {
    //     clearQueryParams: true
    //   }
    // })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

  constructor(private router: Router) {
    // angulartics2GoogleAnalytics.startTracking();
  }

}
