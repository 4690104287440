// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  serverStage: true,
  firebase: {
    apiKey: 'AIzaSyAV8OMbSkbWnlS-jEGJahkZMq70wvV_QvM',
    authDomain: 'sm-stage.firebaseapp.com',
    databaseURL: 'https://sm-stage.firebaseio.com',
    projectId: 'sm-stage',
    storageBucket: 'sm-stage.appspot.com',
    messagingSenderId: '603366774681',
    appId: '1:603366774681:web:5246828dded47e9d3f6508'
  }
};
