import {Component, OnInit} from '@angular/core';
import {RoutingState} from '../../shared/routing-state.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private routingState: RoutingState) {
  }

  ngOnInit() {

    const last = this.routingState.getLastUrl();
    if (last.indexOf('404') === -1) {
      // this.angulartics2.eventTrack.next({
      //   action: '404',
      //   properties: {
      //     category: '404/redirect',
      //     label: last
      //   }
      // });
    }
  }

  goHome() {
    window.location.href = 'https://members.samsung.it/';
  }

}
