import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject
export class DeeplinkModel {

  @JsonProperty('id', Number) id: number = undefined;
  @JsonProperty('deeplink', String) deeplink: string = undefined;
  @JsonProperty('name', String) name: string = undefined;

}
