import {Component, OnInit} from '@angular/core';
import {RoutingState} from '../../shared/routing-state.service';

@Component({
  selector: 'app-guest-toolbar',
  templateUrl: './guest-toolbar.component.html',
  styleUrls: ['./guest-toolbar.component.css']
})
export class GuestToolbarComponent implements OnInit {

  constructor(private routerState: RoutingState) {
  }

  ngOnInit() {
  }


}
