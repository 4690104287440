import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-landing-promo-foldable',
  templateUrl: './landing-promo-foldable.component.html',
  styleUrls: ['./landing-promo-foldable.component.scss']
})
export class LandingPromoFoldableComponent implements OnInit {

  privacyUrl = 'https://firebasestorage.googleapis.com/v0/b/samsung-members.appspot.com/o/privacy%2Ffoldable_2020_privacy.pdf?alt=media&token=cb6a0e20-2375-4d4d-ac96-0ea2e649b251';
  termsUrl = 'https://firebasestorage.googleapis.com/v0/b/samsung-members.appspot.com/o/privacy%2Ffoldable_2020_terms_v2.pdf?alt=media&token=9c64c46a-9d5e-4c39-abdf-2cd8eeadd61b';

  isActive = true;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
    if (new Date().getTime() > 1606089600000) {
      this.isActive = false;
    }
  }

  openPage() {
    this.router.navigateByUrl('/promo-foldable');
  }
}
