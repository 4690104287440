import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {fromEvent} from 'rxjs';

@Injectable()
export class RoutingState {
  private history = [];
  private lastUrl = '';

  constructor(
    private router: Router
  ) {
    fromEvent(window, 'popstate')
      .subscribe((e) => {
        this.history.pop();
      });
  }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects, url}: NavigationEnd) => {
        this.lastUrl = url;
        if (urlAfterRedirects !== ''
          && urlAfterRedirects !== '/'
          && urlAfterRedirects !== '/home'
          && urlAfterRedirects.indexOf('?token=') == -1
          && urlAfterRedirects !== '/loading'
        ) {
          if (this.history.indexOf(urlAfterRedirects) === -1) {
            this.history = [...this.history, urlAfterRedirects];
          }
        }
      });


  }


  public getLastUrl(): string {
    return this.lastUrl;
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }

}
