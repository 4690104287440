import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-landing-promo-tec-week',
  templateUrl: './landing-promo-tec-week.component.html',
  styleUrls: ['./landing-promo-tec-week.component.scss']
})
export class LandingPromoTecWeekComponent implements OnInit {

  privacyUrl = 'https://firebasestorage.googleapis.com/v0/b/samsung-members.appspot.com/o/privacy%2Fdisrupt_privacy.pdf?alt=media&token=9e201355-971c-4e98-a3ba-09ad3b996228';
  termsUrl = 'https://firebasestorage.googleapis.com/v0/b/samsung-members.appspot.com/o/privacy%2Fdisrupt_termini_condizioni.pdf?alt=media&token=f3999597-9273-4a3e-a9bc-4795fff79e6f';

  isActive = true;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
    if (new Date().getTime() > 1603670399000) {
      this.isActive = false;
    }
  }

  openPage() {
    this.router.navigateByUrl('/promo-tec-week');
  }
}
