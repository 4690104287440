import {LOCALE_ID, NgModule} from '@angular/core';
import {AsyncPipe, CommonModule, registerLocaleData} from '@angular/common';
import {LoadingComponent} from '../util/loading/loading.component';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule
} from '@angular/material';
import {FeedService} from '../network/feed.service';
import {AuthGuard} from '../router/auth-guard';
import {HttpClientModule} from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {FormsModule} from '@angular/forms';
import {GenericDialogComponent} from '../util/generic-dialog/generic-dialog.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NoSanitizePipe} from './pipe/no-sanitize.pipe';
import {AppearDirective} from './appear.directive';
import {environment} from '../../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {CookieSnackComponent} from './component/cookie-snack.component';
import {AppRedirectService} from './services/app-redirect.service';
import {GenericNewDialogComponent} from '../util/generic-new-dialog/generic-new-dialog.component';
import {AuthPrGuard} from '../router/auth-pr-guard';
import {SafeUrlPipe} from './pipe/safe-url.pipe';
import {MessagingService} from './services/messaging.service';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireFunctionsModule, FUNCTIONS_REGION} from '@angular/fire/functions';
import {ChristmasNotificationDialogComponent} from '../util/christmas-notification-dialog/christmas-notification-dialog.component';
import {VoucherNotificationDialogComponent} from '../util/voucher-notification-dialog/voucher-notification-dialog.component';

registerLocaleData(localeIt);

const materialModule = [
  MatMenuModule,
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatDividerModule,
  MatGridListModule,
  MatTabsModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  FormsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatRadioModule,
  MatProgressBarModule,
  MatSliderModule
];

const pipesImport = [
  SafeHtmlPipe,
  NoSanitizePipe,
  SafeUrlPipe
];

const directiveImport = [
  AppearDirective
];

const ie = [
  FlexLayoutModule,
  CommonModule,
  HttpClientModule,
  ...materialModule
];

@NgModule({
  imports: [
    ie,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebase, 'samsung-members')
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: FUNCTIONS_REGION, useValue: 'europe-west1'},
    FeedService,
    AuthGuard,
    AuthPrGuard,
    AppRedirectService,
    MessagingService,
    AsyncPipe
  ],
  declarations: [
    GenericDialogComponent,
    GenericNewDialogComponent,
    ChristmasNotificationDialogComponent,
    VoucherNotificationDialogComponent,
    LoadingComponent,
    pipesImport,
    CookieSnackComponent,
    directiveImport
  ],
  entryComponents: [
    LoadingComponent,
    CookieSnackComponent,
    GenericDialogComponent,
    ChristmasNotificationDialogComponent,
    VoucherNotificationDialogComponent,
    GenericNewDialogComponent
  ],
  exports: [
    ie,
    LoadingComponent,
    pipesImport,
    directiveImport
  ]
})
export class SharedModule {
}
