import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-landing-promo-tec-week',
  templateUrl: './landing-promo-ch-holiday.component.html',
  styleUrls: ['./landing-promo-ch-holiday.component.scss']
})
export class LandingPromoChHolidayComponent implements OnInit {

  privacyUrl = 'https://firebasestorage.googleapis.com/v0/b/samsung-members.appspot.com/o/privacy%2Fch-holiday-privacy.pdf?alt=media&token=46884b6c-95a0-420e-bc9b-fc0351fac731';
  termsUrl = 'https://firebasestorage.googleapis.com/v0/b/samsung-members.appspot.com/o/privacy%2Fch-holiday-terms-v2.pdf?alt=media&token=23cb94bc-a9bb-4fe2-99b9-ec07c581e6bc';

  isActive = true;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
    if (new Date().getTime() > 1903670399000) {
      this.isActive = false;
    }
  }

  openPage() {
    this.router.navigateByUrl('/promo-ch-holiday');
  }
}
