import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject
export class User {

  constructor(UID: number, fullname: string, session_token: string) {
    this.UID = UID;
    this.session_token = session_token;
    this.fullname = fullname;
  }

  @JsonProperty('UID', Number)
  UID: number = undefined;

  @JsonProperty('session_token', String)
  session_token: string = undefined;

  @JsonProperty('firebase_jwt', String, true)
  firebase_jwt: string = undefined;

  @JsonProperty('fullname', String)
  fullname: string = undefined;

  @JsonProperty('hasDevices', Boolean, true)
  hasDevices: boolean = undefined;

}
