import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-new-generic-dialog',
  templateUrl: './generic-new-dialog.component.html',
  styleUrls: ['./generic-new-dialog.component.scss']
})
export class GenericNewDialogComponent {

  message = '';
  subMessage = '';
  positiveButton = '';
  negativeButton = '';

  constructor(public dialogRef: MatDialogRef<GenericNewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.subMessage = data.subMessage;
    if (data.positiveButton != null) {
      this.positiveButton = data.positiveButton;
    }
    if (data.negativeButton != null) {
      this.negativeButton = data.negativeButton;
    }
  }
}
