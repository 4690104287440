import {NgModule} from '@angular/core';
import {AppRoutingModule} from './router/app-routing.module';
import {HomeComponent} from './router-component/home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorComponent} from './router-component/error/error.component';
import {NotFoundComponent} from './router-component/not-found/not-found.component';
import {ToolbarComponent} from './layout/toolbar/toolbar.component';
import {SharedModule} from './shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {LoadingPageComponent} from './router-component/loading/loading-page.component';
import {HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import {RoutingState} from './shared/routing-state.service';
import {GestureConfig} from '@angular/material';
import {FooterComponent} from './layout/footer/footer.component';
import {GuestToolbarComponent} from './layout/guest-toolbar/guest-toolbar.component';
import {SamsungToolbarComponent} from './layout/samsung-toolbar/samsung-toolbar.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {WebMobileToolbarComponent} from './layout/web-mobile-toolbar/web-mobile-toolbar.component';
import {LandingPromoTecWeekComponent} from './router-component/promo-tec-week/landing-promo-tec-week/landing-promo-tec-week.component';
import {LandingPromoFoldableComponent} from './router-component/promo-foldable/landing-promo-foldable/landing-promo-foldable.component';
import {LandingPromoChHolidayComponent} from './router-component/promo-ch-holiday/landing-promo-ch-holiday/landing-promo-ch-holiday.component';
import {FirebaseMessaging} from '@angular/fire';
import {AngularFireMessaging} from '@angular/fire/messaging';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    'swipe': {direction: Hammer.DIRECTION_ALL}
  };
}

const moduleDeclarations = [
  AppComponent,
  HomeComponent,
  ErrorComponent,
  NotFoundComponent,
  ToolbarComponent,
  GuestToolbarComponent,
  SamsungToolbarComponent,
  WebMobileToolbarComponent,
  FooterComponent,
  LandingPromoTecWeekComponent,
  LandingPromoFoldableComponent,
  LandingPromoChHolidayComponent
];

const moduleImports = [
  SharedModule,
  AppRoutingModule,
  BrowserModule,
  BrowserAnimationsModule
];


@NgModule({
  imports: [
    moduleImports,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  declarations: [
    moduleDeclarations,
    LoadingPageComponent
  ],
  providers: [
    RoutingState,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(
    routingState: RoutingState
  ) {
    routingState.loadRouting();
  }
}
