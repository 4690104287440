/**
 * Created by ciccio on 19/12/17.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {FeedService} from '../network/feed.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private feed: FeedService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.feed.getUser() == null) {
      this.router.navigate(['/home']);
    }
    return true;
  }
}
