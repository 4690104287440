import {JsonObject, JsonProperty} from 'json2typescript';
import {Offer} from '../offer.model';
import {User} from '../user.model';

@JsonObject
export class AuthModel {

  @JsonProperty('offer', Offer, true)
  offer: Offer = undefined;

  @JsonProperty('user', User)
  user: User = undefined;

  @JsonProperty('fetchDevicesSuccess', Boolean)
  deviceFetched: boolean = undefined;

}
