import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent {

  // TODO nascondere titolo
  showTitle = true;
  title = 'Samsung Members';
  message = '';
  positiveButton = '';
  negativeButton = '';

  constructor(public dialogRef: MatDialogRef<GenericDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    if (data.positiveButton != null) {
      this.positiveButton = data.positiveButton;
    }
    if (data.negativeButton != null) {
      this.negativeButton = data.negativeButton;
    }
    if (data.showTitle != null) {
      this.showTitle = data.showTitle;
    }
  }
}
