/**
 * Created by ciccio on 19/12/17.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {FeedService} from '../network/feed.service';
import {Conf} from '../conf/conf.model';


@Injectable()
export class AuthPrGuard implements CanActivate {

  constructor(
    private router: Router,
    private feed: FeedService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.feed.getUser() == null) {
      sessionStorage.setItem('routeto', state.url);

      window.location.href = Conf.PR_LOGIN_URL;
      return false;
    }
    return true;
  }
}
