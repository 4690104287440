import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {isUndefined} from 'util';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  static loader: any = null;
  centerText = '';

  public static showLoading(dialog: MatDialog): any {
    LoadingComponent.hideLoading();
    const loader: any = dialog.open(LoadingComponent, {
      disableClose: true
    });
    (loader._containerInstance as any)._elementRef.nativeElement.style.backgroundColor = 'transparent';
    (loader._containerInstance as any)._elementRef.nativeElement.style.boxShadow = 'none';
    this.loader = loader;
    return loader;
  }

  public static hideLoading(loader?: any) {
    if (loader != null && !isUndefined(loader)) {
      loader.close();
    } else if (this.loader != null) {
      this.loader.close();
    }
  }

  constructor(public dialogRef: MatDialogRef<void>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public updateCenterText(text: string) {
    this.centerText = text;
  }


}
