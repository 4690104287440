import {JsonObject, JsonProperty} from 'json2typescript';
import {CtaModel} from './cta.model';

@JsonObject
export class Offer {

  @JsonProperty('id', String)
  id: string = undefined;

  @JsonProperty('cta', CtaModel)
  cta: CtaModel = undefined;

}
