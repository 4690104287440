import {Component, Inject, Renderer2} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material';


@Component({
  selector: 'app-cookie-snackbar',
  template: '<div fxLayoutAlign="column" fxLayoutAlign="center center" class="sharp-regular small-text"><div fxFlex="80">Questa app utilizza cookie tecnici essenziali di prima parte. Può conoscere i dettagli consultando la nostra privacy policy <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/samsung-members.appspot.com/o/privacy%2Fsmw_cookie_policy.pdf?alt=media&token=19b77351-91c7-4e8b-ab41-c1ee3f04b6d6">qui.</a></div><div fxFlex="20"><button mat-raised-button (click)="close()">OK</button></div></div>',
  styles: [`
    a:link {
      color: #01b3e3;
    }

    /* A link that has been visited */
    a:visited {
      color: #01b3e3;
    }

    /* A link that is hovered on */
    a:hover {
      color: #01b3e3;
    }

    /* A link that is selected */
    a:active {
      color: #01b3e3;
    }
  `]
})
export class CookieSnackComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data,
              private _snackRef: MatSnackBarRef<CookieSnackComponent>,
              private ren: Renderer2) {
  }

  close() {
    this._snackRef.dismiss();
    localStorage.setItem('cookieSeen', 'true');
  }

}
