import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireFunctions} from '@angular/fire/functions';
import {MatDialog} from '@angular/material/dialog';
import {VoucherNotificationDialogComponent} from '../../util/voucher-notification-dialog/voucher-notification-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  private NOTIFICATION_REQUIRED = 'NOTIFICATION_REQUIRED_BACK_22';
  private PUSH_TOKEN = 'PUSH_TOKEN_BACK_22';
  private LAST_FCM_TOKEN = 'LAST_FCM_TOKEN_BACK_22';
  private RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION_BACK_22';

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireFunctions: AngularFireFunctions,
    private angularFireMessaging: AngularFireMessaging,
    private dialog: MatDialog) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  showDialog() {
    console.log('Notification.permission');
    let receiveNotification = localStorage.getItem(this.RECEIVE_NOTIFICATION);
    console.log('Notification.permission : ' + receiveNotification);
    if (receiveNotification) {
      const date = +receiveNotification;
      const requireAfter = (30 * 24 * 60 * 60 * 1000);
      const now = new Date().getTime();
      if (date + requireAfter < now) {
        receiveNotification = undefined;
      }
    }
    console.log('Notification.permission: ' + Notification.permission);
    if (Notification.permission === 'granted' && !!receiveNotification) {
      this.subscribeToGeneric();
    } else {
      if (!sessionStorage.getItem(this.NOTIFICATION_REQUIRED) && Notification.permission != 'denied') {
        const dial = this.dialog.open(VoucherNotificationDialogComponent, {
          width: '350px',
          disableClose: true
        });
        dial.afterClosed().subscribe(result => {
          sessionStorage.setItem(this.NOTIFICATION_REQUIRED, 'true');
          console.log('result: ' + result);
          if (result) {
            localStorage.setItem(this.RECEIVE_NOTIFICATION, '' + (new Date().getTime()));
            this.requestPermission();
          } else {
            const fcmToken = localStorage.getItem(this.PUSH_TOKEN);
            this.angularFireMessaging.deleteToken(fcmToken);
            localStorage.removeItem(this.PUSH_TOKEN);
            localStorage.removeItem(this.LAST_FCM_TOKEN);
            localStorage.removeItem(this.RECEIVE_NOTIFICATION);
          }
        });
      }
    }
  }

  requestPermission() {
    console.log('requestPermission');
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        localStorage.setItem(this.PUSH_TOKEN, token);
        console.log('token');
        console.log(token);
        this.subscribeToGeneric();
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  subscribeToGeneric() {
    console.log('subscribeToGeneric');
    const receiveNotification = localStorage.getItem(this.RECEIVE_NOTIFICATION);
    if (Notification.permission === 'granted' && receiveNotification) {
      this.angularFireMessaging.requestToken.subscribe(
        (fcmToken) => {
          console.log('fcmToken');
          console.log(fcmToken);
          localStorage.setItem(this.PUSH_TOKEN, fcmToken);
          const lastFcmToken = localStorage.getItem(this.LAST_FCM_TOKEN);
          if (fcmToken != lastFcmToken) {
            this.angularFireFunctions.httpsCallable('subscribeToGenericTopic')({token: fcmToken})
              .toPromise()
              .then(resp => {
                console.log(resp);
                if (resp.topic === 'added') {
                  localStorage.setItem(this.LAST_FCM_TOKEN, fcmToken);
                  console.log('Token function synced');
                }
              })
              .catch(err => {
                console.log('subscribeToVoucher error');
                console.error({err});
              });
          } else {
            console.log('token match');
          }
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
    }
  }

}
