import {Component, OnInit} from '@angular/core';
import {RoutingState} from '../../shared/routing-state.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  constructor(private routerState: RoutingState) {
  }

  ngOnInit() {
  }

  toolbarBack() {
    if (this.routerState.getHistory().length > 1) {
      window.history.back();
    } else {
      window.open('voc://');
      window.close();
    }
  }

}
