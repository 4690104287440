import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})

/**
 * Blocca l'azione del dom sanitizer, utilizzata per i testi contenenti HTML che devono essere mostrati senza che
 * l'HTML venga bloccato/strippato
 */
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(style): any {
    return this.sanitizer.bypassSecurityTrustHtml(style);
  }

}

