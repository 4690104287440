import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FeedService} from './network/feed.service';
import {MatSnackBar} from '@angular/material';
import {CookieSnackComponent} from './shared/component/cookie-snack.component';
import {SwPush} from '@angular/service-worker';

// noinspection TsLint
@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private feed: FeedService,
    private router: Router,
    private snackBar: MatSnackBar,
    private swPush: SwPush) {
    this.initParam();
    this.checkCookie();
  }

  initParam() {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === 'token') {
        this.router.navigateByUrl('/loading', {replaceUrl: true, skipLocationChange: true});
        this.feed.auth(pair[1])
          .then(offer => {
            offer.cta.go(this.feed, this.router, {replaceUrl: true, skipLocationChange: false});
          })
          .catch(
            error => {
              console.log(error);
              this.router.navigateByUrl('/error', {replaceUrl: true, skipLocationChange: true});
            }
          );
      }
    }
  }

  checkCookie() {
    if (!localStorage.getItem('cookieSeen')) {
      this.snackBar.openFromComponent(CookieSnackComponent, {});
    }
  }

  ngOnInit(): void {
    // this.swPush.notificationClicks.subscribe(notpayload => {
    //   console.log(
    //     'Action: ' + notpayload.action +
    //     'Notification data: ' + notpayload.notification.data +
    //     'Notification data.url: ' + notpayload.notification.data.url +
    //     'Notification data.body: ' + notpayload.notification.body
    //   );
    //   // window.open(notpayload.notification.data.url, '_blank');
    //   // https://medium.com/@waelkdouh/interacting-with-push-notifications-under-angular-7-applications-4b6562629201
    //   window.open('https://services.samsung.it/push', '_blank');
    // });
  }

}
